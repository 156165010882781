<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Blog</h1>
  </div>
  <main>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">

      <div>
        <p class="text-sm text-gray-500">
          <time datetime="2020-03-16">July 21, 2021</time>
        </p>
        <a href="#" class="mt-2 block">
          <p class="text-xl font-semibold text-gray-900">
            How to Be More Productive Working From Home
          </p>
          <p class="mt-3 text-base text-gray-500">
            With work at home being a common way to work at your convenience, there is a drawback called lack of productivity. The unprofessional environment can cause this lack of productivity, lack of assistance, and lack of discipline.
          </p>
        </a>
        <div class="mt-3">
          <router-link to="how-to-be-more-productive-working-from-home"
                       class="text-base font-semibold text-indigo-600 hover:text-indigo-500">
            Read full story
          </router-link>
        </div>
      </div>
    </div>
  </main>

  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <a href="https://twitter.com/kairohq" target="_blank" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-left text-base text-gray-400">
          &copy; 2024 Sotak, Ltd. All rights reserved. Contact: support@getkairo.com | <router-link to="blog">Blog</router-link><br />
          <router-link to="terms">Terms of Service</router-link> | <router-link to="privacy-policy">Privacy Policy</router-link> | <router-link to="acceptable-use-policy">Acceptable Use Policy</router-link>
        </p>
      </div>
    </div>
  </footer>
</template>
